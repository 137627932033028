.hero-banner {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background: var(--yellow-mandarine-gradient);

  color: var(--black);

  /* responsive */

  margin: 0 auto;
  padding: 3.2rem 6.4rem;
}

.hero-banner--yellow-mandarine {
  background: var(--yellow-mandarine-gradient);
}
.hero-banner--pink-yellow {
  background: var(--pink-yellow-gradient);
}

.hero-banner--blue-aqua {
  background: var(--blue-aqua-gradient);
}

.hero-banner--aqua-emerald {
  background: var(--aqua-emerald-gradient);
}

.hero-banner--emerald-yellow {
  background: var(--emerald-yellow-gradient);
}

.hero-banner__logo {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--white);
  border-radius: 50%;

  box-shadow: 0 2px 4px rgb(0 0 0 / 12%);

  /* responsive */

  width: 12.8rem;
  height: 12.8rem;
}

.hero-banner__image {
  width: 10.8rem;
  height: 10.8rem;
}

.hero-banner__image--small {
  width: 7.6rem;
  height: 7.6rem;
}

.hero-banner__headline {
  letter-spacing: -1.5px;

  /* responsive */

  margin: 2.4rem 0 8px 0;

  font-size: 4.8rem;
}

.hero-banner__description {
  max-width: 58rem;

  text-align: center;

  /* responsive */

  margin-bottom: 3.2rem;

  font-size: 20px;
  line-height: 3.2rem;
}

@media only screen and (max-width: 540px) {
  .hero-banner {
    padding: 3.2rem 1.6rem;
  }

  .hero-banner__logo {
    width: 9.6rem;
    height: 9.6rem;
  }

  .hero-banner__image {
    width: 7.2rem;
    height: 7.2rem;
  }

  .hero-banner__image--small {
    width: 6rem;
    height: 6rem;
  }

  .hero-banner__headline {
    font-size: 3.2rem;
  }

  .hero-banner__description {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
